import axios from 'axios';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { App } from 'App';
import { baseURL } from 'constants';

import 'tippy.js/dist/tippy.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import "swiper/swiper-bundle.css";

axios.defaults.baseURL = `${baseURL}/v1/dapp`;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<StrictMode>
		<App />
	</StrictMode>
);
