import { useMemo } from 'react';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { BrowserRouter, Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';

import { Overview } from 'pages/Overview';

import { adminWalletPublicAddress } from 'constants';

export const Routes = () => {
	const { publicKey } = useWallet();

	const connectedWalletPublicAddress = useMemo(() => publicKey && new PublicKey(publicKey).toString(), [publicKey]);

	return (
		<BrowserRouter>
			<RouterRoutes>
				<Route index element={<Overview />} /> 
				<Route path="*" element={<Navigate to="/" replace />} />
			</RouterRoutes>
		</BrowserRouter>
	);
};
