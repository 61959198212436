import { PublicKey } from "@solana/web3.js";
import { NavLink, Link } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { memo, useContext, useMemo, useCallback } from "react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

import { Image } from "components/Image";

import { BalancesContext } from "contexts/Balances";
import { adminWalletPublicAddress, getNavigationItems } from "constants";
import { numberWithCommas } from "utils";

const PureFooter = ({ onWalletButtonClick }) => {
  const { publicKey } = useWallet();
  const { setVisible } = useWalletModal();
  const { wallet, connect, connecting, connected: isConnected } = useWallet();
  const { account, isLoadingAccountBalance } = useContext(BalancesContext);

  const connectedWalletPublicAddress = useMemo(
    () => publicKey && new PublicKey(publicKey).toString(),
    [publicKey]
  );

  const handleSelectWalletButtonClick = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleConnectButtonClick = useCallback(() => {
    // NOTE: Connection promise acts weird and works only after second click hens adding this hack here.
    connect().then(() => connect());
  }, [connect]);

  return (
    <footer>
      <div className="container">
        <div className="row">
          
          
          <div className="col-12 col-md-8 order-md-1">
            &copy; 2023 AIOXperts &ndash; All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export const Footer = memo(PureFooter);
