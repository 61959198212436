import { gsap } from 'gsap';
import { Helmet } from 'react-helmet-async';
import React, { useCallback, useState } from 'react';
import { useEventListener, useLockedBody } from 'usehooks-ts';

import { Image } from 'components/Image';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
//import { ConvertTokensModal } from 'components/ConvertTokensModal';

const appTitle = 'Fleet Manager App';
const getPageTitle = title => (title ? `${appTitle} | ${title}` : appTitle);

export const Layout = ({ children, title }) => {
	const [isAsideOpen, setIsAsideOpen] = useState(false);
	const [isBridgeModalOpen, setIsBridgeModalOpen] = useState(false);
	const [isAtaOptionsModalOpen, setIsAtaOptionsModalOpen] = useState(false);
	//const [isConvertTokensModalOpen, setIsConvertTokensModalOpen] = useState(false);

	const handleOpenAside = useCallback(() => {
		if (!isAsideOpen) {
			gsap.to('#wallet', { duration: 0.75, x: '0%', ease: 'power3.inOut' });
			gsap.to('#wallet .close-btn', { duration: 0.5, delay: 0.5, x: '0%', ease: 'power3.out' });
			setIsAsideOpen(true);
		}
	}, [isAsideOpen]);

	const handleCloseAside = useCallback(() => {
		if (isAsideOpen) {
			gsap.to('#wallet', { duration: 0.75, delay: 0.15, x: '100%', ease: 'power3.inOut' });
			gsap.to('#wallet .close-btn', { duration: 0.65, x: '100%', ease: 'power3.inOut' });
			setIsAsideOpen(false);
		}
	}, [isAsideOpen]);

	const handleConvertTokensButtonClick = useCallback(props => {
		//setIsConvertTokensModalOpen(true);
		//gsap.to('#convert-tokens', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseConvertTokensModal = useCallback(() => {
		//gsap.to('.modal-success-view', { duration: 0.5, opacity: 0, display: 'none' });
		//gsap.to('#convert-tokens', { duration: 0.5, opacity: 0, display: 'none' });
		//setIsConvertTokensModalOpen(false);
	}, []);

	const handleBridgeButtonClick = useCallback(props => {
		setIsBridgeModalOpen(true);
		gsap.to('#bridge', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseBridgeModal = useCallback(() => {
		gsap.to('.modal-success-view', { duration: 0.5, opacity: 0, display: 'none' });
		gsap.to('#bridge', { duration: 0.5, opacity: 0, display: 'none' });
		setIsBridgeModalOpen(false);
	}, []);

	const handleShowAtaOptionsButtonClick = useCallback(() => {
		setIsAtaOptionsModalOpen(true);
		gsap.to('#ata-options', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseAtaOptionsModal = useCallback(() => {
		gsap.to('#ata-options', { duration: 0.5, opacity: 0, display: 'none' });
		setIsAtaOptionsModalOpen(false);
	}, []);

	useLockedBody(/*isConvertTokensModalOpen ||*/ isAtaOptionsModalOpen || isBridgeModalOpen);

	useEventListener('keyup', event => {
		if (event.key === 'Escape') {
			//handleCloseConvertTokensModal();
			handleCloseAtaOptionsModal();
			handleCloseBridgeModal();
		}
	});

	return (
		<>
			<Helmet>
				<title>{getPageTitle(title)}</title>
			</Helmet>
			<Header onWalletButtonClick={handleOpenAside} />
			

			<div className="container">
				<main>{children}</main>
				{/*<ConvertTokensModal isOpen={isConvertTokensModalOpen} onClose={handleCloseConvertTokensModal} />*/}
			</div>
			<Footer/>
		</>
	);
};
