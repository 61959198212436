import { PublicKey } from "@solana/web3.js";
import { NavLink, Link } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { memo, useContext, useMemo, useCallback } from "react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

import { Image } from "components/Image";

import { BalancesContext } from "contexts/Balances";
import { adminWalletPublicAddress, getNavigationItems } from "constants";
import { numberWithCommas } from "utils";

const PureHeader = ({ onWalletButtonClick }) => {
  const { publicKey } = useWallet();
  const { setVisible } = useWalletModal();
  const { wallet, connect, connecting, connected: isConnected } = useWallet();
  const { account, isLoadingAccountBalance } = useContext(BalancesContext);

  const connectedWalletPublicAddress = useMemo(
    () => publicKey && new PublicKey(publicKey).toString(),
    [publicKey]
  );

  const handleSelectWalletButtonClick = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleConnectButtonClick = useCallback(() => {
    // NOTE: Connection promise acts weird and works only after second click hens adding this hack here.
    connect().then(() => connect());
  }, [connect]);

  return (
    <header>
      <div className="container">
        <div className="row top-bar">
          <div className="col-12 col-md-10 order-md-2">
            <div className="header-card">
              <span className="corner-border-top"></span>
              <h3 className="headline3">
                Fleet Manager
              </h3>
              <span className="corner-border-bottom"></span>
            </div>
          </div>
          <div className="col-6 col-md-1 order-md-1">
            <div className="header-card">
              <span className="corner-border-top"></span>
              <a href="/">
                <img
                  src="images/logo.png"
                  alt="Logo"
                  className="img-fluid logo"
                />
              </a>
              <span className="corner-border-bottom"></span>
            </div>
          </div>
          <div className="col-6 col-md-1 order-md-3">
            <div className="header-card">
              <span className="corner-border-top"></span>
              <button
                className="main-menu-toggle collapsed"
                type="button"
                data-toggle="collapse"
                href="#main-menu"
              >
                <i className="fa fa-bars"></i>
              </button>
              <span className="corner-border-bottom"></span>
            </div>
          </div>
        </div>
      </div>
      <div id="main-menu" className="collapse megamenu">
        <div className="container">
          <div className="menu-card">
            <div className="row">
              <div className="col-6 col-md">
                {getNavigationItems.map(({ title, href }) => (
                  <NavLink to={href} key={title} end>
                    {title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export const Header = memo(PureHeader);
