import { Layout } from "components/Layout";
// eslint-disable-next-line
import { A11y, Navigation, Pagination, Scrollbar, Mousewheel } from 'swiper';
// eslint-disable-next-line
import { Swiper, SwiperSlide } from 'swiper/react';

export const Overview = () => {
  return (
    <Layout title="Overview">
      <div className="container">
        <h1 className="headline-alert">Welcome to the Fleet Manager</h1>
        <p>Home to the automatic Star Atlas Resupplier</p>
        <hr />
      </div>
      <Swiper
        modules={[Pagination, Mousewheel, Navigation, Scrollbar]}
        onSwiper={(swiper) => (window.swiper = swiper)}
        slidesPerView={1}
        threshold={1}
        spaceBetween={10}
        navigation
        scrollbar
        mousewheel={{
          forceToAxis: true,
          sensitivity: 0.1,
          releaseOnEdges: true,
        }}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <div className="carousel-item active">
            <img
              src="images/hubble-carina-nebula-landscape_2.jpg"
              className="d-block w-100"
              alt="..."
            />
            <div className="carousel-caption  active">
              <h5>First slide label</h5>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="carousel-item">
            <img
              src="images/hubble-carina-nebula-landscape_2.jpg"
              className="d-block w-100"
              alt="..."
            />
            <div className="carousel-caption">
              <h5>Second slide label</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="container my-5">
        <h1 className="headline-alert">Main headline</h1>
        <p className="lead">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <hr className="my-5" />
        <div className="related-products">
          <h2 className="headline1 mb-3">New Products</h2>
          <Swiper
            modules={[Pagination, Mousewheel, Navigation, Scrollbar]}
            onSwiper={(swiper) => (window.swiper = swiper)}
            slidesPerView={4}
            threshold={3}
            spaceBetween={10}
            navigation
            scrollbar
            mousewheel={{
              forceToAxis: true,
              sensitivity: 0.1,
              releaseOnEdges: true,
            }}
            style={{ height: "430px" }}
            pagination={{ clickable: true }}
          >
            <SwiperSlide style={{ width: "277px" }}>
              <div className="card h-100 mb-0">
                <span className="corner-border-top"></span>
                <img
                  src="images/hubble-carina-nebula-square.jpg"
                  className="card-img-top"
                  alt="Carina Nebula captured by the Hubble Space Telescope"
                />
                <div className="card-body">
                  <h5 className="card-title">Carina Nebula Print</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    from 19,90 €
                  </h6>
                  <a href="#" className="btn btn-primary">
                    <i className="fa fa-fw fa-shopping-cart"></i> Add to cart
                  </a>
                </div>
                <span className="corner-border-bottom"></span>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ width: "277px" }}>
              <div className="card h-100 mb-0">
                <span className="corner-border-top"></span>
                <img
                  src="images/hubble-carina-nebula-square.jpg"
                  className="card-img-top"
                  alt="Carina Nebula captured by the Hubble Space Telescope"
                />
                <div className="card-body">
                  <h5 className="card-title">Carina Nebula Print</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    from 19,90 €
                  </h6>
                  <a href="#" className="btn btn-primary">
                    <i className="fa fa-fw fa-shopping-cart"></i> Add to cart
                  </a>
                </div>
                <span className="corner-border-bottom"></span>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ width: "277px" }}>
              <div className="card h-100 mb-0">
                <span className="corner-border-top"></span>
                <img
                  src="images/hubble-carina-nebula-square.jpg"
                  className="card-img-top"
                  alt="Carina Nebula captured by the Hubble Space Telescope"
                />
                <div className="card-body">
                  <h5 className="card-title">Carina Nebula Print</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    from 19,90 €
                  </h6>
                  <a href="#" className="btn btn-primary">
                    <i className="fa fa-fw fa-shopping-cart"></i> Add to cart
                  </a>
                </div>
                <span className="corner-border-bottom"></span>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ width: "277px" }}>
              <div className="card h-100 mb-0">
                <span className="corner-border-top"></span>
                <img
                  src="images/hubble-carina-nebula-square.jpg"
                  className="card-img-top"
                  alt="Carina Nebula captured by the Hubble Space Telescope"
                />
                <div className="card-body">
                  <h5 className="card-title">Carina Nebula Print</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    from 19,90 €
                  </h6>
                  <a href="#" className="btn btn-primary">
                    <i className="fa fa-fw fa-shopping-cart"></i> Add to cart
                  </a>
                </div>
                <span className="corner-border-bottom"></span>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="container-fullwidth parallax-container mb-5">
          <img
            src="images/hubble-carina-nebula-landscape_2.jpg"
            className="parallax-image"
          />
          <div className="container text-center py-5">
            <h2 className="headline1 mb-5">Never Miss Another Resupply</h2>
            <p className="lead">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="row no-gutters">
                <div className="col-6">
                  <img
                    src="images/hubble-carina-nebula-square.jpg"
                    className="card-img-top convert-to-bg"
                    alt="Carina Nebula captured by the Hubble Space Telescope"
                  />
                  <div className="card-img-overlay small">(c) NASA</div>
                </div>
                <div className="col-6">
                  <div className="card-body">
                    <h5 className="card-title">Carina Nebula</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      The "Mystic Mountains"
                    </h6>
                    <p className="card-text">
                      The Hubble Space Telescope captured this view of the
                      Carina Nebula. It was quickly dubbed the "Mystic
                      Mountains".
                    </p>
                    <a href="#" className="btn btn-primary mb-0">
                      Read more
                    </a>
                  </div>
                  <div className="card-footer small text-muted">3 days ago</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="row no-gutters">
                <div className="col-6">
                  <img
                    src="images/hubble-carina-nebula-square.jpg"
                    className="card-img-top convert-to-bg"
                    alt="Carina Nebula captured by the Hubble Space Telescope"
                  />
                  <div className="card-img-overlay small">(c) NASA</div>
                </div>
                <div className="col-6">
                  <div className="card-body">
                    <h5 className="card-title">Carina Nebula</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      The "Mystic Mountains"
                    </h6>
                    <p className="card-text">
                      The Hubble Space Telescope captured this view of the
                      Carina Nebula. It was quickly dubbed the "Mystic
                      Mountains".
                    </p>
                    <a href="#" className="btn btn-primary mb-0">
                      Read more
                    </a>
                  </div>
                  <div className="card-footer small text-muted">5 days ago</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron text-center py-4">
          <div
            className="avatar avatar-lg mb-3"
            style={{
              backgroundImage: 'url("images/hubble-carina-nebula-square.jpg")',
            }}
          >
            <img src="images/hubble-carina-nebula-square.jpg" />
          </div>
          <h1 className="headline1">Lorem ipsum dolor sit amet?</h1>
          <p className="lead">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <hr className="my-4" />
          <p className="text-muted text-right">a satisfied customer</p>
        </div>
        <div className="container-fullwidth parallax-container mb-5">
          <img
            src="images/hubble-carina-nebula-landscape.jpg"
            className="parallax-image"
          />
          <div className="container text-center py-5">
            <div
              id="carouselTextMultiple"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#carouselTextMultiple"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#carouselTextMultiple" data-slide-to="1"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row row-cols-2 row-cols-md-4">
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row row-cols-2 row-cols-md-4">
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card mb-0 bg-dark">
                        <div className="card-body text-center">
                          <div
                            className="avatar"
                            style={{
                              backgroundImage:
                                'url("images/hubble-carina-nebula-square.jpg")',
                            }}
                          >
                            <img src="images/hubble-carina-nebula-square.jpg" />
                          </div>
                          <p>
                            Border color changes inside cards to match the card
                            color.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselTextMultiple"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselTextMultiple"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <h2 className="headline1 mb-4">Frequently Asked Questions</h2>
        <div className="accordion" id="accordionFAQ">
          <div className="card">
            <div className="card-header" id="headingOne">
              <button
                className="btn btn-block btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                How do I use a HTML Template?
                <i className="fa fa-fw fa-chevron-up"></i>
                <i className="fa fa-fw fa-chevron-down"></i>
              </button>
            </div>

            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionFAQ"
            >
              <div className="card-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <button
                className="btn btn-block btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How do I use Bootstrap?
                <i className="fa fa-fw fa-chevron-up"></i>
                <i className="fa fa-fw fa-chevron-down"></i>
              </button>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionFAQ"
            >
              <div className="card-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <button
                className="btn btn-block btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How do I play the game?
                <i className="fa fa-fw fa-chevron-up"></i>
                <i className="fa fa-fw fa-chevron-down"></i>
              </button>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionFAQ"
            >
              <div className="card-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
          </div>
        </div>
        <hr className="my-5" />
        <h2 className="headline1 mb-4">
          If you still have questions, please contact us!
        </h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Your name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Please enter your name ..."
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Your email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Please enter your email adress ..."
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Your message</label>
            <textarea
              className="form-control"
              id="message"
              rows="5"
              placeholder="Please enter your message ..."
            ></textarea>
          </div>
          <button className="btn btn-highlight">Send Message</button>
        </form>
      </div>
    </Layout>
  );
};
